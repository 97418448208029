* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;

}

@font-face {
  font-family: "inter-bold";
  src: url(../fonts/inter-bold.ttf);
}

@font-face {
  font-family: "inter-medium";
  src: url(../fonts/inter-medium.ttf);
}

@font-face {
  font-family: "inter-regular";
  src: url(../fonts/inter-regular.ttf);
}

@font-face {
  font-family: "inter-semibold";
  src: url(../fonts/inter-semibold.ttf);
}

@font-face {
  font-family: "poppins-medium";
  src: url(../fonts/poppins-medium.ttf);
}

@font-face {
  font-family: "poppins-semibold";
  src: url(../fonts/poppins-semibold.ttf);
}

@font-face {
  font-family: "poppins-regular";
  src: url(../fonts/poppins-regular.ttf);
}

@font-face {
  font-family: "poppins-bold";
  src: url(../fonts/poppins-bold.ttf);
}

@font-face {
  font-family: "poppins-light";
  src: url(../fonts/Poppins-Light.ttf);
}

@font-face {
  font-family: "antrysans";
  src: url(../fonts/ANTRYSANS_PERSONAL_USE_ONLY.OTF);
}

.d-flex,
.res-menu-icon {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.btn_optional {
  display: none;
}

.text-center {
  text-align: center;
  margin-top: 30px;

}

.login-box {
  margin-top: 40px;
}

.text-center .login-page-heading {
  color: #fff;
}

.input-group .email-address-heading {
  font-size: 14px;
  margin-bottom: 3px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-auto {
  margin-left: auto;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.h-100 {
  height: 100%;
}

.body-overflow {
  overflow: hidden;
}

.header-main {
  background: #003d90;
  padding: 4px 15px;
}

.position-relative {
  position: relative;
}

.header-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-ul {
  display: flex;
  color: #d4d9e1;
}

.header-ul li {
  font-family: "inter-regular";
  font-size: 14px;
  width: auto;
  padding: 0 10px;
}

.logo-img {
  width: 178px;
  object-fit: cover;
  height: 38px;
}

.header-ul li a {
  text-decoration: none;
  color: #d4d9e1;
}

.p-15 {
  padding: 15px;
}

.title-header {
  display: flex;
  align-items: center;
}

.title-header-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

}

.search {
  border: 1px solid #d4d9e1;
  width: 200px !important;
  height: 35px;
  line-height: 31px;
  border-radius: 4px !important;
  margin-right: 10px !important;
}

.search input::placeholder {
  color: #919cac;
  opacity: 1;
}

.search input {
  font-size: 12px;
  line-height: 15px;
  padding: 5px 14px !important;
  font-family: "inter-regular";
}

.dashboard-text p,
.title-main .page-title {
  color: #3e3e3e;
  font-size: 19px;
  line-height: 20px;
  font-family: "inter-bold";
}

.common-button button,
.common-button button:hover {
  font-size: 12px;
  font-family: "inter-semibold";
  padding: 7px 13px 6px 13px;
  line-height: 19px;
  letter-spacing: 0px;
  border-radius: 4px;
  text-transform: capitalize;
}

.grey-button button,
.grey-button button:hover {
  background: #fff;
  border: 1px solid #eaac35;
  color: #eaac35;
  border-radius: 4px;
}

.blue-button button,
.blue-button button:hover {
  background: orangered;
  color: #ffffff;
  margin-left: 10px;
  border-radius: 4px;
  border: 0px;
  padding: 7px 8px;
}

.sahay-table,
.table-container {
  background-color: #1a1a1a;
  margin-top: 40px;
}

.sahay-table .table-container {
  background-color: #1a1a1a;
}

.table-design-main tr th,
.table-design-main tr td {
  font-size: 16px;
  color: #122b45;
}

.table-design-main tr th {
  font-family: "inter-semibold";
  padding: 4px 9px !important;
}

.table-design-main tr td {
  font-family: "inter-regular";
  padding: 10px !important;
  border-bottom: 0 !important;
}

.table-design-main {
  box-shadow: none !important;
  border: 0 !important;
}

.table-design-main .unstack-btn {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d1d5db;
  color: #6b7280;
}

.invoice-table tr td {
  background: #fff;
  display: table-cell;
  text-align: center;
}

.input-design-div .password {
  margin-top: 25px !important;
}

.input-design-div .MuiFilledInput-underline:before,
.input-design-div .MuiFilledInput-underline:after,
.input-design-div .MuiFilledInput-underline:hover:before {
  border-bottom: 0 !important;
}

.input-design-div .MuiFilledInput-underline,
.input-design-div .MuiFilledInput-underline:hover,
.input-design-div .MuiFilledInput-underline.Mui-focused {
  background: #fff;
  border: 1px solid #dfe0e1;
  border-radius: 4px;
  height: 30px;
}

.input-design-div .input-placeholder input::placeholder {
  color: #122b45;
  font-family: "inter-regular";
  line-height: 20px;
}

.input-group .admin-input-design {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 5px;
}

.forgot .forgot_password {
  color: #fff;
}

.input-design-div.with-border input {
  height: 35px;
  line-height: 28px;
  box-sizing: border-box;
  padding: 0 15px;
}

.input-design-div.with-border input,
.set-textarea-box-top .MuiInputBase-formControl {
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-family: "inter-regular";
  font-size: 14px;
}

.set-textarea-box-top fieldset {
  border: none !important;
}

.with-border .MuiFilledInput-underline {
  height: 35px !important;

  font-size: 14px !important;
  letter-spacing: 0;
}

.input-label {
  color: #122b45;
  font-size: 14px;
  line-height: 15px;
  font-family: "inter-semibold";
  padding-bottom: 8px !important;
  display: flex;
}

.line {
  height: 1px;
  background: #ebebeb;
}

.save-btn button {
  min-width: 110px;
}

.table-container {
  box-shadow: none !important;
}

.set-date .MuiStack-root {
  overflow: hidden;
  padding-top: 0px;
}

.set-date-picker .MuiInputBase-colorPrimary {
  background: #fff;
  border: 1px solid #00000033 !important;
  border-radius: 4px;
  width: 100%;
  height: 35px;
  border-radius: 5px;
}

.set-date-picker fieldset {
  border-color: transparent !important;
}

.set-date-picker input {
  padding: 0px 14px !important;
  color: #120d26;
  font-size: 14px !important;
  line-height: 19px;
  font-family: "inter-regular" !important;
  text-align: left;
  height: 28px;
}

.set-date-picker .MuiStack-root {
  padding-top: 0px !important;
}

.set-calendar-box .set-calendar-position,
.set-date-picker .MuiStack-root,
.input-design-div-people {
  width: 100%;
}

.accestimeicon {
  fill: #757575 !important;
}

.accestimeicon-img {
  position: absolute;
  top: 5px;
  right: 8px;
  display: flex;
}

.accestimeicon-img svg {
  height: 19px;
}

.set-date-picker svg {
  height: 19px;
}

.pagination-design nav {
  display: flex;
}

.pagination-design nav ul li .MuiPaginationItem-previousNext {
  color: #003d90;
  background-color: transparent;
}

.pagination-design nav ul li button,
.pagination-design nav ul li button:hover {
  margin: 0;
  font-family: "inter-medium";
  color: #fff;
  background: #3f3f3f;
  min-width: 25px;
  height: 25px;
  margin: 0 5px;
  position: relative;
  padding: 0;
}

.pagination-design nav ul li button svg {
  display: none;
}

.pagination-design nav ul li:first-child button,
.pagination-design nav ul li:last-child button {
  margin: 0;
}

.pagination-design nav ul li:first-child button::after,
.pagination-design nav ul li:last-child button::after {
  content: "";
  width: 10px;
  height: 12px;
  background-image: url(../images/png/leftArrow.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.pagination-design nav ul li:last-child button::after {
  background-image: url(../images/png/rightArrow.png);
}

.pagination-design nav ul li:first-child button:hover,
.pagination-design nav ul li:last-child button:hover {
  background: transparent;
}


.pagination-design nav ul li button.Mui-selected,
.pagination-design nav ul li button.Mui-selected:hover {
  background: #ff4d1d;
  color: #000;
}

.pagination-design {
  padding-top: 15px;
}

.filter-btn button,
.filter-btn button:hover {
  max-width: 34px;
  height: 34px;
  min-width: 34px;
  background: #003d90;
  border: 1px solid #003d90;
}


.main-login {
  background-image: url("../images/png/bg.png");

  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
}

.login-page-top-side {
  background-image: url("../images/png/shape.png");
  height: 260px;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
}

.login-page-left-side {
  width: 50%;
  color: #fff;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-page-left-img {
  height: 500px;

  margin-top: 0px;
}

.login-page-right-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  flex: 1;
  align-items: center;

  height: 100vh;
}

.login-page-right-side-contained {
  width: 500px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.rightbox {
  background: #042443c4;
  height: 100vh;
  margin-left: auto;
  max-width: 50%;
  border-radius: 0px 0px 0px 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.white-login-main {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-set {
  padding: 30px 0px 0px 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.logo-set img {
  height: 81px;
  width: 383px;
  object-fit: contain;
}

.logo-set2 img {
  width: 164px;
  object-fit: contain;
}

.box-text p {
  color: white;
  font-size: 32px;
  line-height: 39px;
  font-family: "inter-bold";
}

.box-login-text p {
  font-family: "inter-regular";
  font-size: 16px;
  color: white;
  line-height: 20px;
  margin: 15px 0px 40px 0px;
}

.main-box {
  max-width: 400px !important;
  width: 400px;
  flex: 0 0 400px;
  padding-top: 75px;
}

.forgot-password-heading {
  margin: 30px 0px 20px 0px;
}

.forgot-input-field {
  margin: 10px 0px 20px 0px;
}

.login-btn-main .login-page-link {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.login-btn-main {
  margin: 30px 0px 20px;
}

.login-btn-main a {
  text-decoration: none;
  color: #fff;
}

.login-btn button {
  width: 100%;
  font-family: "inter-semibold";
}

.orange-btn button,
.orange-btn button:hover,
.orange-btn .buttons,
.orange-btn .buttons:hover {
  background: #fb923c;
  color: #000000;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  box-shadow: none;
  min-height: 36px;
  font-family: "inter-regular";
  text-transform: capitalize;
  letter-spacing: 0.5px;
  text-decoration: none !important;
  min-width: 300px;
}

.forgot p {
  margin: 0px !important;
  text-align: right !important;
}

.input-design-div .MuiInputBase-input {
  padding: 11px 10px !important;
  color: #122b45;
  background-color: #fff;
  border-radius: 4px;
  font-size: 14px;
  padding-right: 30px;
}

.dashboard-text p {
  color: #003d90;
  font-size: 16px;
  line-height: 20px;
  font-family: "inter-bold";
  display: flex;
}

.main-box-dash {
  background: #f0f3f8;
  border-radius: 8px;
  max-width: 18.81%;
  min-height: 73px;
  padding: 14px 20px;
  width: calc(100% / 5);
  margin: 10px 7.5px 15px;
}

.text-main p {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  font-family: "inter-medium";
  margin-bottom: 5px;
}

.number-text p {
  font-size: 16px;
  line-height: 20px;
  font-family: "inter-bold";
  color: #122b45;
}

.logo-dash img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.dash-text {
  margin-left: 20px !important;
}

.dash-text-box-set {
  display: flex;
  align-items: center;
}

.set-dash-main {
  display: flex;
  width: calc(100% + 15px);
  margin-left: -7.5px;
  flex-wrap: wrap;
  margin-right: -7.5px;
}

.forgot a {
  text-decoration: none;
}

.barge-list-main-box {
  border-radius: 8px;
  max-width: 100%;
  min-height: 104px;
  background: #f0f3f8;
}

.barge-set-dash-main {
  display: flex;
  margin-left: -6.5px;
  flex-wrap: wrap;
  margin-right: -6.5px;
}

.brge-icon .brge-icon-set {
  width: 66px;
  height: 59px;
}

.set-progress {
  margin: 3px 0px 1px;
}

.set-progress span {
  width: 66px !important;
  height: 4px !important;
}

.set-progress .MuiLinearProgress-bar {
  background-color: #0d70d9 !important;
}

.brge-number .text {
  font-size: 8px;
  line-height: 10px;
  font-family: "inter-medium";
  color: #122b45;
}

.brge-img-set {
  padding: 12px 15px 10px;
  width: 30%;
  border-right: 1px solid #dfe0e1;
}

.barge-set-text-box {
  padding: 8px 8px 10px 15px;
  width: 100%;
  text-align: left;
}

.main-barge-box {
  display: flex;
}

.set-scheduled .text {
  font-size: 8px;
  line-height: 10px;
  font-family: "inter-regular";
  color: white;
  padding: 1px 6px;
}

.set-scheduled {
  border-radius: 30px;
  width: 100%;
  max-width: max-content;
  margin-left: auto;
  height: 12px;
}

.set-scheduled-text .text-name {
  font-size: 10px;
  line-height: 12px;
  font-family: "inter-regular";
  color: #8d98ab;
}

.set-scheduled-text .text-span {
  font-size: 10px;
  line-height: 12px;
  font-family: "inter-medium";
  color: #122b45;
}

.pt-5 {
  padding-top: 5px !important;
}

.set-scheduled-back {
  background: #51c6e5;
}

.set-available-back {
  background-color: #05e39c;
}

.main-bargebox {
  display: flex;
  width: 100%;
}

.todaytrip-list-main-box {
  background: #f0f3f8;
  border-radius: 8px;
  max-width: 100%;
  min-height: 104px;
}

.dashboard-row-main .dashboard-item,
.dashboard-item {
  padding: 0 !important;
  box-shadow: none !important;
  background: transparent;
  text-align: center !important;
}

.background-ed {
  background: #fff;
  border-radius: 10px;
}

.today-trip-text {
  padding-top: 12px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.today-set-progress.set-progress .MuiLinearProgress-bar {
  background-color: #e8b153 !important;
}

.admin-input-design {
  width: 100%;
}

.profile-set {
  font-family: "inter-regular";
  font-size: 14px;
  width: auto;
  padding: 0 10px;
  color: #d4d9e1;
  text-transform: capitalize;
}

.flex-drop-main {
  display: flex;
  align-items: center;
}

.admin-header-profile-icon {
  height: 26px;
  width: 26px;
  object-fit: cover;
  border-radius: 50%;
}

.arrow-profile-icon {
  height: 5px;
  width: 5px;
  object-fit: cover;
}

.admin-header-profile-ul a li {
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  font-family: "poppins-regular";
  padding: 7.5px 16px;
}

.admin-header-profile-ul a {
  text-decoration: none !important;
}

.logout-profile {
  color: #ff0000 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  font-family: "poppins-regular" !important;
}

.admin-profile-box .MuiPaper-rounded {
  top: 50px !important;
  right: 32px !important;
  box-shadow: 0px 3px 6px #0000000d !important;
  border-radius: 10px !important;
  left: 0px !important;
}

.admin-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.filter-header {
  background: #edeff1;
  padding: 15px 20px;
}

.filter-main .MuiPaper-elevation {
  min-width: 350px;
}

.filter-header-inner .filter-title {
  color: #003d90;
  font-family: "inter-bold";
  font-size: 16px;
  line-height: 20px;
}

.filter-footer {
  height: 50px;
  border: 1px solid #edeff1;
  padding: 10px 20px;
}

.filter-inner-main {
  height: calc(100vh - 100px);
  overflow: auto;
  padding: 20px;
}

.filter-header-inner {
  justify-content: space-between;
}

.outline-button button,
.outline-button button:hover {
  background: transparent;
  border: 1px solid #eaac35;
  color: #eaac35;
  border-radius: 4px;
}

.profile-content {
  height: 100%;
  min-height: calc(100vh - 120px);
}

.card-main {
  background: #f0f3f8;
  padding: 30px;
  box-shadow: 0px 3px 30px #0000000d;
  border-radius: 10px;
}

.profile-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  display: flex;
  margin: auto;
  margin-bottom: 15px;
}

.profile-card-main {
  width: 710px;
}

.profile-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  display: flex;
  margin-bottom: 15px;
}

.change-input-label {
  color: #122b45;
  font-size: 14px;
  line-height: 15px;
  font-family: "inter-medium";
  padding-bottom: 6px !important;
  display: flex;
}

.set-textarea-box {
  height: 47px;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 10px 16px;
  resize: none;
}

.set-textarea-box:focus {
  outline: none;
}

.change-profile {
  display: flex;
  justify-content: center;
  position: relative;
}

input.change-profile-input {
  position: absolute;
  top: 1px;
  background: red;
  height: 28px;
  right: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
}

.change-password-set {
  margin-bottom: 20px;
}

.change-password-main {
  min-width: 370px;
}

.change-space {
  margin-bottom: 15px;
}

.supply-table tr th:nth-child(1),
.supply-table tr td:nth-child(1) {
  width: 6%;
}

.supply-table tr th:nth-child(2),
.supply-table tr td:nth-child(2) {
  width: 10%;
}

.supply-table tr th:nth-child(3),
.supply-table tr td:nth-child(3) {
  width: 10%;
}

.supply-table tr th:nth-child(4),
.supply-table tr td:nth-child(4) {
  width: 5%;
}

.supply-table tr th:nth-child(5),
.supply-table tr td:nth-child(5) {
  width: 11%;
}

.supply-table tr th:nth-child(6),
.supply-table tr td:nth-child(6) {
  width: 6%;
}

.supply-table tr th:nth-child(7),
.supply-table tr td:nth-child(7) {
  width: 8%;
}

.supply-table tr th:nth-child(8),
.supply-table tr td:nth-child(8) {
  width: 5%;
}

.supply-table tr th:nth-child(9),
.supply-table tr td:nth-child(9) {
  width: 5%;
}

.supply-table tr th:nth-child(10),
.supply-table tr td:nth-child(10) {
  width: 4%;
}

.supply-table tr th:nth-child(11),
.supply-table tr td:nth-child(11) {
  width: 7%;
}

.supply-table tr th:nth-child(12),
.supply-table tr td:nth-child(12) {
  width: 10%;
}

.supply-table tr th:nth-child(13),
.supply-table tr td:nth-child(13) {
  width: 18%;
}

.supply-table tr th:nth-child(14),
.supply-table tr td:nth-child(14) {
  width: 10%;
}

.tanker-table tr th:nth-child(1),
.tanker-table tr td:nth-child(1) {
  width: 12%;
}

.tanker-table tr th:nth-child(2),
.tanker-table tr td:nth-child(2) {
  width: 20%;
}

.tanker-table tr th:nth-child(3),
.tanker-table tr td:nth-child(3) {
  width: 12%;
}

.tanker-table tr th:nth-child(4),
.tanker-table tr td:nth-child(4) {
  width: 14%;
}

.tanker-table tr th:nth-child(5),
.tanker-table tr td:nth-child(5) {
  width: 18%;
}

.tanker-table tr th:nth-child(6),
.tanker-table tr td:nth-child(6) {
  width: 17%;
}

.tanker-table tr th:nth-child(7),
.tanker-table tr td:nth-child(7) {
  width: 20%;
}

.change-password-btn button {
  margin-left: 0 !important;
  margin-top: 5px;
}

.res-menu {
  display: none;
}

.res-menu-icon svg {
  fill: #fff;
  width: 20px;
  height: 20px;
}

.res-menu-icon svg {
  display: none;
}

.res-close-btn {
  display: none !important;
}

.header-flex-main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.set-filter-main {
  padding-top: 60px;
}

.background-hight {
  height: 100%;
}

.header-ul-main-open {
  left: -250px !important;
}

.menu-set-main .MuiMenu-paper {
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  width: 160px;
}

.menu-set-main ul {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.no-text-decoration {
  text-decoration: none;
}

.margin-left-5 {
  margin-left: 5px !important;
}


.span-text {
  color: #d1d5db;
  margin-left: 3px;
}

.modal .modal-title {
  color: #111827;
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins-Medium";
}

.modal-user-btn-flex {
  display: flex;
  justify-content: flex-end;
  padding-top: 3px;
}

.modal .add-user-modal-inner-main {
  padding: 24px 24px 30px 24px;
  max-width: 500px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 10px;
}

.modal-input-box {
  margin-bottom: 24px;
}

.modal-input-box .form-group .form-control input {
  padding: 5.5px 15px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}


.user-circle-img {
  width: 52px;
  height: 52px;
  object-fit: contain;
  margin: auto;
  display: flex;
}

.modal-delete .delete-modal-inner-main {
  padding: 40px 24px 40px 24px;
  max-width: 300px;
  width: calc(100% - 30px);
  border: 1px solid #707070;
  border-radius: 10px;
}

.delete-modal-inner-main .delete-modal-title {
  color: #111827;
  font-size: 28px;
  line-height: 42px;
  font-family: "inter-medium";
  text-align: center;
  margin: 20px 0px 16px;
}

.delete-modal-inner-main .delete-modal-para {
  color: grey;
  margin-bottom: 26px;
  text-align: center;
  max-width: 304px;
  width: 100%;
  margin: auto;
}

.delete-modal-btn-flex {
  display: flex;
  justify-content: center;
  margin-top: 26px;
}

.delete-modal-btn-flex .modal-btn {
  border-radius: 4px;
  border: 1px solid #d1d5db;
  max-width: 100px;
  width: 100%;
  padding: 8px;
  text-transform: capitalize;
}

.delete-modal-btn-flex .modal-cancel-btn,
.delete-modal-btn-flex .modal-cancel-btn:hover {
  background: #fff;
  border: 1px solid #eaac35;
  color: #eaac35;
  border-radius: 10px;
  margin-right: 20px;
}

.delete-modal-btn-flex .modal-delete-btn,
.delete-modal-btn-flex .modal-delete-btn:hover {
  color: #ffffff;
  background: #eaac35;
  border-radius: 10px;
}

.MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(17 24 39 / 60%);
}


.incre-btn-main .inc-dic-btn,
.incre-btn-main .inc-dic-btn:hover {
  background-color: #003d90;
  height: 34px;
  min-width: 34px;
  color: #fff;
  line-height: 23px;
  font-size: 17px;
  margin-top: 15px;
  border-radius: 5px;
  padding-bottom: 8px;
}

.incre-btn-main .inc-dic-btn:first-child {
  margin-right: 15px;
}

.incre-btn-main .Mui-disabled {
  color: #c5c1c1 !important;
}

.Mui-error {
  margin-left: 0px !important;
}

.action-menu-icon {
  font-size: 20px !important;
}

.action-text {
  font-family: "inter-regular" !important;
  color: #122b45 !important;
  font-size: 13px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.filter-datepicker .MuiOutlinedInput-input {
  border: 0 !important;
}

.filter-date-overflow>.MuiStack-root {
  overflow: hidden;
}

.pdf-design.MuiBox-root.css-0 {
  font-family: "inter-medium";
  font-size: 14px;
  background: #003d90;
  color: #d8d8dd;
  padding: 8px;
  text-align: center;
  border-radius: 5px;
}

.pdf-upload-design {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

.filter-close-btn img {
  height: 23px;
  width: 23px;
}

.modal-close-button {
  height: 23px;
  width: 23px;
}

.select-compny-cad-main {
  max-width: 1118px !important;
  width: 100%;
}

.select-card-main {
  background: #fff;
  padding: 50px;
  box-shadow: 0px 3px 30px #0000000d;
  border: 1px solid #e2e2e2;
  border-radius: 15px;
}

.title-main .select-page-title {
  color: #000000;
  font-size: 30px;
  line-height: 36px;
  font-family: "inter-bold";
  text-align: center;
}

.compny-img {
  border: 2px solid #d9d9d9;
  border-radius: 15px;
  padding: 20.5px;
  background: white;
  cursor: pointer;
}

.compny-img-inner {
  width: 196px;
  height: 158px;
  border-radius: 10px;
}

.set-select-img {
  text-align: center;
}

.select-compny-text {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-family: "inter-semibold";
  color: #122b45;
}

.select-text-set {
  margin-top: 20px;
}

.select-img-box {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.select-img-main {
  margin: 9px;
  width: 241px;
}

.mb-41 {
  margin-bottom: 41px;
}

.select-check-box {
  text-align: center;
  justify-content: center;
  margin-top: 15px;
}

.select-check-box svg {
  width: 30px;
  height: 30px;
}

.select-check-box .MuiCheckbox-colorPrimary {
  padding-top: 16px 0px 0px !important;
}

.select-modal-box {
  max-width: 551px !important;
  width: 100% !important;
  border: 1px solid #707070 !important;
  padding: 0px !important;
  border-radius: 20px !important;
  outline: none;
  cursor: pointer;
}

.select-modal-header {
  background: #003d90;
  padding: 15px 20px;

  border-radius: 19px 19px 0px 0px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.select-modal-header-text {
  font-size: 18px;
  color: white;
  line-height: 20px;
  font-family: "inter-semibold";
}

.select-modal-img-box {
  padding: 40px 50px;
}

.select-text-set-modal {
  margin-top: 9px;
}

.modal-compny-img-inner {
  width: 94px;
  height: 101px;
  border-radius: 10px;
}

.modal-compny-img {
  border: 2px solid #d9d9d9;
  border-radius: 15px;
  padding: 13px 14px;
  background: white;
}

.modal-select-img-box {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.select-img-main-modal {
  margin: 14px;
  width: 130px;
}

.modal-set-select-img {
  text-align: center;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
}

.modal-select-compny-text {
  text-align: center;
  font-size: 15px;
  line-height: 19px;
  font-family: "inter-semibold";
  color: #122b45;
}

.set-modal-close-icon {
  height: 30px;
  width: 30px;
}

.modal-close-img {
  height: 16px;
  width: 16px;
}

.modal-close-icon-small {
  height: 20px;
  width: 20px;
}

.modal-icon-btn {
  margin-top: 11px !important;
}

.res-btn-close {
  min-width: auto !important;
  padding: 0px !important;
}

.modal-compny-img .active {
  border: 1px solid #1f65db;
}

.select-img-main-modal.active .modal-compny-img {
  border: 2px solid #1f65db;
}

.select-img-main-modal.active .modal-icon-btn .modal-close-icon-small-grey {
  display: none;
}

.select-img-main-modal.active .modal-icon-btn .modal-close-icon-small-blue {
  display: flex;
  margin: auto;
}

.select-img-main-modal .modal-icon-btn .modal-close-icon-small-grey {
  display: flex;
  margin: auto;
}

.select-img-main-modal .modal-icon-btn .modal-close-icon-small-blue {
  display: none;
}

.select-img-main.active .compny-img {
  border: 2px solid #1f65db;
}

.select-img-main.active .select-check-box .modal-close-icon-small-blue {
  display: block;
  margin: auto;
}

.select-img-main.active .select-check-box .modal-close-icon-small-grey {
  display: none;
}

.select-img-main .select-check-box .modal-close-icon-small-grey {
  display: block;
  margin: auto;
}

.select-img-main .select-check-box .modal-close-icon-small-blue {
  display: none;
}

.barge-dashboard-box {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #dfe0e1;
  border-radius: 10px;
}

.barge-common-box {
  background-color: #1a1a1a;
}

.table-design-main {
  background-color: #f0f3f8;
}

.dashboard-containt-main {
  padding: 60px 30px 0px;
}

.filter-date-overflow .MuiStack-root {
  width: 100%;
}

.barge-dashboard-page {
  margin-top: 30px;
  margin-bottom: 30px;
}

.one-line-table tr th,
.one-line-table tr td {
  white-space: nowrap;
}

.table-design-main tr td:last-child button.MuiIconButton-sizeMedium {
  padding: 3.5px;
}

.input-design-div .MuiInputBase-fullWidth {
  border-radius: 4px;
  height: 35px;
  line-height: 25px;
}

.input-design-div .set-date-picker .MuiInputBase-input {
  background: transparent;
}


.white-login-box.MuiBox-root.css-0 {
 
  padding: 50px;
  border-radius: 15px;
  padding-top: 0px;
}

.bluebox-text p {
  color: #122b45;
}

.login-input-design-div .MuiFilledInput-underline,
.login-input-design-div .MuiFilledInput-underline:hover,
.login-input-design-div .MuiFilledInput-underline.Mui-focused {
  height: 42px;
  border: 1px solid #d4d9e1;
}

.logo-set2 {

  margin-top: 0px;
}




.profile-image-center {
  text-align: center;
}

.input-design-div-set-back .MuiInputBase-input {
  background-color: transparent !important;
}

.change-password-icon .admin-input-design .MuiInputAdornment-filled button svg {
  width: 18px;
  height: 18px;
}

.change-password-icon .MuiInputBase-input {
  font-size: 14px !important;
}

.change-password-text {
  margin-bottom: 20px;
}

.input-design-div .password-set {
  margin-bottom: 20px !important;
  margin-top: 0px !important;
}

.input-design-div .password-set-box {
  margin-top: 0px !important;
}

.input-design-div .label-set {
  margin-top: 0px !important;
}

.input-label-new {
  background: #f0f3f8 !important;
}


body .MuiPopover-paper {
  box-shadow: none !important;
  border-radius: 8px;
  border: 1px solid #e1dddd;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}


::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}

.table-design-main tr th {
  background: #ff4d1d;
  padding: 15px 10px !important;
  white-space: nowrap;
  border-bottom: none;
  color: #000;
  font-size: 18px;
  font-family: "poppins-medium";
}

.dashboard-containt-main {
  padding: 60px 30px 0px;
  background: #f0f3f8 !important;
}

.table-design-main {
  background-color: #1a1a1a;
  border-collapse: separate !important;
  border-radius: 0px !important;
}

.table-design-main tr th:first-child,
.table-design-main tr td:first-child {
  padding-left: 20px !important;
}

.table-design-main tr th:last-child,
.table-design-main tr td:last-child {
  padding-right: 20px !important;
}

.barge-common-box-table {
  background-color: #f0f3f8;
  padding: 0px !important;

  border-radius: 10px;
}



.table-design-main tr td {
  background: #1a1a1a;
  display: table-cell;
  color: #fff;
}

.table-design-main tr th:first-child {
  border-radius: 3px 0px 0px 3px;
}

.table-design-main tr th:last-child {
  border-radius: 0 3px 3px 0 !important;
}

.profile-inner .dashboard-item {
  background: transparent;
}

.set-textarea-box {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 10px 16px;
  resize: none;
}

.set-profile-set {
  font-size: 14px;
  font-family: "inter-bold";
  line-height: 20px;
  color: #122b45;
  padding: 0px 8px;
  text-transform: capitalize;
  display: flex;
}

.set-admin-header-profile-icon {
  width: 33px !important;
  height: 33px !important;
}

.set-profile-inner {
  font-size: 8px;
  line-height: 10px;
  color: #959595;
  padding: 0px 8px;
  font-family: "inter-regular";
}

.new-menu-set-main .MuiMenu-paper {
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
  top: 51px !important;
  width: 200px;
}

.pink-btn-menu {
  background: #ffb8d0 !important;
  text-align: center !important;
  justify-content: center !important;
  border-radius: 6px !important;
  margin: 0px 16px !important;
}

.drop-header-btn-set {
  padding: 0px 0px 6px !important;
}

.menu-set-main ul {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.menu-set-main .MuiMenu-paper {
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 10px;
}

.css-2hzjrb-MuiInputBase-input-MuiFilledInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(0 0 0) !important;
}

.table-design-main tr td:first-child {
  padding-left: 20px !important;
  white-space: nowrap;
}

.input-design-div fieldset {
  border-color: #ccc;
}

.invoice-table tr td {
  white-space: nowrap;
}

.paiddot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: green;
  border-radius: 50%;
  margin-right: 10px;
}

.unpaiddot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: red;
  border-radius: 50%;
  margin-right: 10px;
}

.MuiPopover-root .MuiPaper-root .MuiList-root li.Mui-selected {
  background: #a6ccff;
}

.input-design-div .MuiOutlinedInput-root:hover fieldset {
  border-color: #ccc;
  border-width: 1px;
}

.MuiOutlinedInput-root .MuiSelect-select .multiselect-item {
  display: inline-flex;
  background: #c9e0ff;
  font-size: 10px;
  padding: 0 8px;
  border-radius: 3px;
  line-height: 2;
  font-family: "poppins-semibold";
  color: #003d90;
}

.invoice_number {
  display: flex;
  justify-content: space-between;
}

.invoice_number_align {
  margin-left: 10px !important;
  font-size: 16px !important;
}

.party_btn {
  display: flex !important;
  justify-content: start;
  margin-right: "-20px";
}

.admin-page-title {
  color: #003d90;
  font-size: 19px;
  line-height: 20px;
  font-family: "inter-bold";
}

.sidebar-logo-back {
  width: 100%;
  height: auto;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-design-main .cus-table-checkbox {
  padding: 0;
}

.cus-table-wrapper .table-action-btn {
  padding: 0 10px;
  text-transform: capitalize;
}

.active-status {
  background: #3d7b0b !important;
}

.inactive-status {
  background: #d62929 !important;
}

.cus-table-checkbox .MuiSvgIcon-root {
  color: #ff4d1d;
}

.table-design-main tr th .cus-table-checkbox .MuiSvgIcon-root {
  color: #1f0903;
}

.sidebar-li.active img {
  filter: brightness(10);
}

.sidebar-li img {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.dropdown-box .form-control {
  width: 100%;
  position: relative;
  border-radius: 10px;
  background: #1a1a1a;
}

.dropdown-select .MuiSelect-select {
  color: #fff;
  font-size: 16px;
  font-family: "Poppins-regular";
  display: flex;
  padding: 10px 15px;
  border-radius: 4px;
}

.grey-down {
  position: absolute;
  top: 17.92px;
  right: 21.92px;
  height: 10px;
  width: 10px;
  object-fit: contain;
}

.dropdown-box .MuiMenu-list .drop-menuitem {
  color: #000;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
}


.main-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 30px 0;
  min-height: 55px;
}

.dropdown-box .MuiSvgIcon-root {
  color: #fff;
  font-size: 40px;
}

.header-drop-box {
  width: 180px;
}

.dropdown-select.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none;
}

.MuiMenu-root .MuiPaper-root {
  background: #1a1a1a;
  color: #fff;
  border: none;
}

.MuiPopover-root .MuiPaper-root .MuiList-root li.Mui-selected,
.MuiPopover-root .MuiPaper-root .MuiList-root li:hover {
  background: #434343;
}

.admin-dashboad-row {
  margin-bottom: 24px;
}

.display-row>* {
  padding: 0px 15px;
}

.display-row {
  margin-left: -15px;
  margin-right: -15px;
}

.primary-btn-main {
  width: 100%;
}

.primary-btn-main .primary-btn,
.primary-btn-main .primary-btn:hover {
  background: #fb923c;
  border-radius: 10px;
  font-size: 21px;
  line-height: 22px;
  font-family: "poppins-bold";
  height: 62px;
  width: 100%;
  text-transform: capitalize;
  color: #000;
}


.right-dashboard-content header {
  background: #000;
}

.sidebar-main.active .sidebar-li span,
.sidebar-main.active .cus-cms-wrap span,
.sidebar-main.active .cms-text svg {
  display: block;
}

.sidebar-main .sidebar-li span,
.sidebar-main .cus-cms-wrap span,
.sidebar-main .cms-text svg {
  display: none;
}


.cus-table-wrapper .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #3d7b0b;
}

.cus-table-wrapper .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: #3d7b0b;
}

.cus-table-wrapper .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase {
  color: #c10000;
}

.cus-table-wrapper .MuiSwitch-track {
  background-color: #c10000;
}


.user-circle-img {
  width: 52px;
  height: 52px;
  object-fit: contain;
  margin: auto;
  display: flex;
}

.modal-delete .delete-modal-inner-main {
  padding: 40px 24px 40px 24px;
  max-width: 500px;
  width: calc(100% - 30px);
  border-radius: 10px;
  background-color: #1a1a1a;
  border: none;
}

.delete-modal-inner-main .delete-modal-title {
  color: #ffffff;
  font-size: 28px;
  line-height: 42px;
  font-family: "Poppins-Medium";
  text-align: center;
  margin: 20px 0px 16px;
}

.delete-modal-inner-main .delete-modal-para {
  color: #fff;
  margin-bottom: 26px;
  text-align: center;
  max-width: 345px;
  width: 100%;
  margin: auto;
  font-family: "poppins-regular";
}

.delete-modal-btn-flex {
  display: flex;
  justify-content: center;
  margin-top: 26px;
}

.delete-modal-btn-flex .modal-btn,
.delete-modal-btn-flex .modal-btn:hover {
  border-radius: 4px;
  border: 1px solid #d1d5db;
  max-width: 100px;
  width: 100%;
  padding: 8px;
  text-transform: capitalize;
}

.delete-modal-btn-flex .modal-cancel-btn {
  color: #6b7280;
  margin-right: 20px;
}

.delete-modal-btn-flex .modal-delete-btn,
.delete-modal-btn-flex .modal-delete-btn:hover {
  color: #ffffff;
  background-color: #ff4d1d;
  border: none;
}

.MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(17 24 39 / 60%);
}

.modal-delete .MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}


.table-design-main tr td .table-img {
  width: 80px;
  height: 40px;
  object-fit: contain;
}

.error-text {
  color: #c43d3d;
}

.cus-drop-ul .cus-drop-li.active {
  background-color: #ff4d1d;
  color: #ffffff;
}


.primary_btn_icon_remove img {
  display: none;
}


.user-list-table .table-design-main tr th {
  padding: 15px 0 15px 10px;
}

.header-button-box {
  display: flex;
  align-items: center;

}

.header-button-box .primary-btn-main .primary-btn.add-game-btn,
.header-button-box .primary-btn-main .primary-btn.add-game-btn:hover {
  min-width: 200px;
}

.not-found-row .not-found-image-cell {
  min-height: 500px;
}

.not-found-text {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-profile-form .pass_group_main {
  background-color: #000000;
}

.header-button-box .primary-btn-main .primary-btn.add-game-btn,
.header-button-box .primary-btn-main .primary-btn.add-game-btn:hover {
  min-width: 200px;
}

.header-button-box .primary-btn-main {
  margin: 0 0px 0 0px;
}

.header-button-box .primary-btn-main:nth-child(2) {
  margin: 0 0px 0 20px !important;
}

.Toastify__toast-theme--light {
  background: #1a1a1a !important;
  border-radius: 10px;
  color: #fff;
  font-family: "poppins-medium";
  font-size: 14px;
}

.no-data-img {
  min-height: 520px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiMenu-root .MuiPaper-root {
  box-shadow: rgb(0 0 0) 0px 1px 1px 0px inset, rgb(0 0 0) 0px 50px 100px -20px,
    rgb(0 0 0) 0px 30px 60px -30px !important;
  border-radius: 0 0 10px 10px;
}

.table-body {
  position: relative;
}

.drop-header-menu .MuiModal-backdrop+div+.MuiPaper-root {
  margin-top: 13px;
}

.sidebar-main.active .cus-drop-arrow,
.sidebar-main.active .cus-drop-arrowup {
  display: block;
}

.sidebar-main .cus-drop-arrow,
.sidebar-main .cus-drop-arrowup {
  display: none;
}

.Toastify__close-button--light {
  color: #fff;
  opacity: 1;
}

.stak-menu-img {
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin: 0 15px 0 0;
}

.sidebar-main .dropdown-select .MuiSelect-select {
 
  padding: 15px 20px;
  font-size: 14px;
  line-height: 21px;
  color: #9d8f8f;
  font-family: "poppins-bold";
  background: #0e0e0e;
  border-radius: 0;
  align-items: center;
}

.sidebar-dropdown .MuiButtonBase-root {
  padding: 15px 20px;
  font-size: 14px;
  line-height: 21px;
  color: #9d8f8f;
  font-family: "poppins-bold";
  cursor: pointer;
}

.MuiList-root .stake-menuitem {
  padding: 15px 20px;
  font-size: 14px;
  line-height: 21px;
  color: #9d8f8f;
  font-family: "poppins-bold";
  cursor: pointer;
}



.drop-body .MuiMenu-list {
  padding: 0;
}

.cms-wrapper {
  border-radius: 10px;
  background-color: #1e1e1e;
  padding: 15px 25px 15px 25px;
}

.cms-wrapper .input-label {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
  font-family: "Poppins-Medium";
  margin-bottom: 15px;
  color: #ffffff;
  margin-top: 0px;
  padding: 0 !important;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused),
.ck.ck-editor__main>.ck-editor__editable.ck-focused {
  background: #161616;
  border-color: #3e3e3e;
  color: #fff;
  min-height: 300px;
}

.ck.ck-toolbar.ck-toolbar_grouping {
  background: #161616;
  border: 1px solid #3e3e3e;
}

.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  color: #fff;
}

.save-btn-main .save-user-btn,
.save-btn-main .save-user-btn:hover {
  background-color: #ff4d1d;
  color: #ffffff;
  border: none;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover {
  background: #161616;
}

.cms-wrapper .primary-btn-main .primary-btn.add-game-btn {
  width: 200px;
}

.cms-wrapper .primary-btn-main .primary-btn.add-game-btn img {
  display: none;
}

.cms-wrapper .save-btn-main {
  margin: 20px 0 0 0;
}

.drop-body .MuiMenu-paper {
  left: 0px !important;
}

.drop-body .MuiMenu-root .MuiPaper-root {
  background: transparent;
  color: transparent;
  border: transparent;
}

.drop-body .MuiMenu-list {
  padding: 0;
  background: #1a1a1a;
  color: #fff;
  border: none;
}

.ml-0 {
  margin-left: 0;
}

.add-lock-period-main .game-upload-name {
  margin-left: 0;
  width: 100%;
}

.add-lock-period-main .game-upload-body .dropdown-select .MuiSelect-select {
  padding: 17.07px 15px;
}

.add-lock-period-main .dropdown-box .form-control {
  border-radius: 5px;
}