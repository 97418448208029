.header-wrapper {
    background: #000000;
    border-bottom: 2px solid #333333;
}

.home-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 34px 0;
}

.header-logo {
    display: flex;
}

.home-logo {
    width: 244px;
    height: 66px;
    object-fit: contain;
    cursor: pointer;
}

.home-main .header-ul {
    display: flex;
    align-items: center;
    padding: 0;
}

.header-ul .header-li {
    margin-right: 46px;
    padding: 10px 0px;
    position: relative;
}

.header-ul .header-li:last-child {
    margin-right: 0;
}

.header-li a {
    font-size: 20px;
    line-height: 28px;
    color: #fff;
    font-family: 'poppins-medium';
    cursor: pointer;
    text-decoration: none;
}

.header-li.active a {
    color: #ff4d1d;
}

.header-ul .header-li.active::after {
    content: '';
    width: 100%;
    height: 3px;
    border-radius: 1.5px;
    background: #ff4d1d;
    position: absolute;
    bottom: 0;
    left: 0;
}

.footer-wrapper {
    background: #000;
    padding-top: 20px;
}

.footer-main {
    display: flex;
    justify-content: space-between;
    padding: 0 0 18px 0;
    gap: 30px;
}

.footer-privacy .footer-logo {
    width: 244px;
    height: 66px;
    object-fit: contain;
    margin: 0 0 30px 0;

}

.footer-privacy .footer-link {
    font-size: 20px;
    line-height: 28px;
    color: #fff;
    font-family: 'poppins-medium';
    text-decoration: none;
    margin: 0 0 12px 0;
    cursor: pointer;
}

.footer-privacy a {
    text-decoration: none;
}

.footer-social-link .social-link {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    background: #661f0c;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s ease;
}

.footer-social-link .social-link.active,
.footer-social-link .social-link:hover {
    background: #ff4d1d;
}

.footer-social-link .social-link:last-child {
    margin-right: 0;
}

.footer-social-link {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
}

.footer-social-link-sec {
    width: 100%;
    max-width: 570px;
}

.footer-social-link-sec .social-link-text {
    font-size: 19px;
    line-height: 38px;
    color: #fff;
    font-family: 'poppins-medium';
    margin: 43px 0 0 0;
}

.copyright-sec {
    border-top: 2px solid #333333;
    background: #000;
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copyright-main .copyright-text {
    font-size: 20px;
    line-height: 26px;
    color: #fff;
    font-family: 'poppins-medium';
}

.header-list {
    display: flex;
}

.header-list .meta-wallet .metamask-btn {
    margin: 0 0 0 20px;

}

.meta-address {
    color: white;
    background-color: #ff4d1d;
    padding: 1px 2px;
    border-radius: 10px;
    font-family: 'poppins-medium';
    font-size: 14px;
    line-height: 16px;
}

.meta-box {
    display: flex;
    align-items: center;
}

.meta-wallet .metamask-img {
    width: 40px;
}

.meta-wallet .metamask-btn {
    text-transform: capitalize;
    text-align: left;
    font-size: 16px;
    font-size: 14px;
    line-height: 21px;
    color: #fff;
    font-family: "poppins-semibold";
    margin-right: 20px;
}

.meta-wallet .metamask-btn img {
    margin-right: 10px;
}

.header-wrapper .mobile-menu-btn,
.header-ul .mobile-nav-close {
    display: none;
}

.metamask-disconnect.MuiMenu-root .MuiPaper-root{
    border-radius: 6px;
    margin-top: 5px;
}

.metamask-disconnect.MuiMenu-root .MuiMenuItem-root{
    min-height: unset;
    font-size: 12px;
    line-height: 16px;
}